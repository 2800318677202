@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@viewport {
  width: device-width;
  initial-scale: 1;
}

/* Base styles */
body {
  min-width: 100%; /* Use percentage for better scalability */
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.my-lottie-animation {
  border-radius: 50%;
  overflow: hidden;
  width: 100%; /* Ensure it scales with the container */
  max-width: 300px; /* Set a max width for larger screens */
  height: auto; /* Maintain aspect ratio */
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  padding: 0 1rem; /* Add some padding for better spacing */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Media queries for different screen sizes */
@media screen and (max-width: 1024px) {
  .header {
    height: 15vh;
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 12vh;
  }
}

@media screen and (max-width: 600px) {
  .header {
    height: 15vh;
    flex-direction: column; /* Stack items vertically on smaller screens */
  }
  .header-div {
    margin-bottom: -130px;
  }
  .nav-menu {
    flex-direction: column !important;
  }
  #about {
    padding: 12px;
    margin-top: 12px;
  }

  #about .flex {
    flex-direction: column;
    align-items: center;
  }

  #about .flex > div {
    width: 100%;
    text-align: center;
  }

  #about .flex > .lottie-container {
    width: 100%;
    height: auto;
  }

  #about h2,
  #about h3 {
    font-size: 1.5rem;
  }

  #about p {
    font-size: 1rem;
  }
}
