@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.section {
  padding: 2em 0;
}

.section-title {
  margin-bottom: 1em;
}

.projects-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* If you want to add some space between the project cards */
.projects-grid > * {
  margin: 1em;
}

.fade-in {
  transition: opacity 1s;
  opacity: 1;
}

.fade-out {
  transition: opacity 1s;
  opacity: 0;
}

.svg-container {
  width: 100%;
  max-width: 1600px; /* Adjust as needed */
  margin: 0 auto; /* Center the SVG horizontally */
}

.svg-container svg {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any inline spacing */
}

.text-dark-blue {
  color: #003366; /* Dark blue color for better contrast */
}

.bg-dark-blue {
  background-color: #003366; /* Dark blue background for better contrast */
}

.text-white {
  color: #ffffff; /* White color for text */
}

.text-sm {
  font-size: 0.875rem; /* Small text size */
}

.p-10 {
  padding: 2.5rem; /* Padding */
}

.animate-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
